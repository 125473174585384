import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-time-input',
    templateUrl: './time-input.component.html',
    styleUrls: ['./time-input.component.scss']
})
export class TimeInputComponent implements OnInit {

    @Input() public labelText = '';
    @Input() public value = '00:00:00';
    @Input() public showTimeFormatInfo = true;
    public readonly maskDateHour = [/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/];

    @Output() public valueChanged = new EventEmitter<string>();

    constructor() {
    }

    ngOnInit(): void {
    }

    change(): void {
        this.valueChanged.emit(this.value);
    }
}
