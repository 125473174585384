import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from "../../models/localization/language.model";

@Injectable({
  providedIn: 'root'
})
export class LTranslateService {

  constructor(
    private translate: TranslateService
  ) { 

    let list: Language[] = LTranslateService.list();
    let codeList: string[] = list.map( (z: Language) => {
      return z.code;
    });

    this.translate.addLangs(codeList);
    this.translate.setDefaultLang("tr");
    this.translate.use('tr');
  }

  public static getSelected(): Language{

    return LTranslateService.getDefault();

  }

  public static list(): Language[]{

    return [new Language(1, "Türkçe", "tr", true), new Language(2, "English", "en", false)];

  }

  public static getLanguage(code: string): Language{

    let list: Language[] = LTranslateService.list();

    let lang = list.find(z => {
      return (z.code == code);
    });

    return lang;
  }
  public static getDefault(): Language{

    let list: Language[] = LTranslateService.list();

    return list.find(data => {
      return (data.isDefault == true);
    });
  }
}
