<div class="auth-wrapper">
  <div class="auth-content">
    <div class="card">
      <div class="row align-items-center text-center">
        <div class="col-md-12">
          <div class="card-body">
            <img src="assets/images/sportistlogo.png" alt="" class="img-fluid mb-4">
            <h4 class="mb-3 f-w-400">Sportist Admin</h4>
            <div class="row">
              <div class="col-md-12">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" >
                  <div class="form-group mb-3">
                      <app-phone-input (formattedValue)="setPhone($event)" ></app-phone-input>
<!--                    <input type="text" formControlName="phone" class="form-control" placeholder="Telefon Numaranız">-->
                  </div>
                  <div class="form-group mb-4">
                    <input type="password" formControlName="password" class="form-control" placeholder="Parolanız">
                  </div>
                  <button class="btn btn-block btn-primary mb-4">Giriş Yap</button>
                </form>
              </div>
              <div class="col-md-12 mt-2">
                <a [routerLink]="['/forget-password']">
                  {{ 'forget_password' | translate }}
                </a>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-select-role [roles]="loginedUserRoleList"></app-select-role>
