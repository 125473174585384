import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Pagination} from '../../../models/pagination/pagination.model';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

    @Input() pagination: Pagination;
    @Output() public changed = new EventEmitter<number>();

    constructor() {
    }

    ngOnInit(): void {
    }

    public pageIn(page): void {
        this.changed.emit(page);
    }

}
