import { Injectable } from '@angular/core';
import { ProfilePhoto } from 'src/app/models/user/user.model';
import { Package } from 'src/app/models/package/package.model';
import * as XLSX from "xlsx";

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor() { }

  getUserProfilePhoto(image?: ProfilePhoto): string{
    if (image != null) {
      return image.url;
    }else return `assets/images/default-profile-pic.jpeg`;
  }

  getRoleText(isCoach: boolean): string{
    if( isCoach ) return 'Eğitmen';
    else return 'Danışan';
  }
  getGenderText(gender?: string): string{
    if( gender == 'f' ) return 'Kadın';
    else if( gender == 'm' ) return 'Erkek';
    else return '';
  }
  getGenderTranslation(gender?: string): string{
    switch (gender) {

      case 'f':
        return 'female';
      case 'm':
        return 'male';
      default:
        return 'unknown';

    }
  }
  calculateAge(dob?: string): number{
    if (dob !== null) {
      
      let dobDate = new Date(dob);
      let currentDate = new Date();

      return currentDate.getFullYear() - dobDate.getFullYear();

    }else return 0;
  }
  googleMapCoordinateUrl(xPos: number, yPos: number): string{
    return `http://www.google.com/maps/place/${xPos},${yPos}`;
  }
  getDayName(dayOfWeek: number): string{
    let dayNames: string[] = ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'];

    return dayNames[dayOfWeek - 1];
  }
  fileToBase64 = (file: File|undefined): Promise<string|null> => {
    return new Promise<string> ((resolve,reject)=> {
          if (file != undefined) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.toString());
            reader.onerror = error => reject(error);
          }else resolve(null);
          
      })
  }
  fileToBase64WithoutMime = (file: File|undefined): Promise<string|null> => {
    return new Promise<string> ((resolve,reject)=> {
          if (file != undefined) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(
              (reader.result.toString()).replace('data:', '')
        .replace(/^.+,/, ''));
            reader.onerror = error => reject(error);
          }else resolve(null);
          
      })
  }
  getPackageTypeName(type: number): string{
    let types = Package.types;

    let obj = types.find( x => x.name == type )
    return obj.value;

  }
  getPackagePublishType(type: number): {name: number, value: string, showValue: string, color: string}{
    let types = Package.publishTypes;

    let obj = types.find( x => x.name == type );
    return obj;

  }
  readableDate(date: Date|string): string{

    if( typeof date == 'string') date = new Date(date);
    
    return `${date.getDay()}.${date.getMonth()}.${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;

  }

  excelExport(exportData: any[], fileName:string) {
    //let element = document.getElementById('invoice-content');
    /* generate worksheet */
    //const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, fileName);
    XLSX.writeFile(wb, fileName+'.xlsx');
  }
}
