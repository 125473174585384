import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {Role} from 'src/app/models/role/role.model';
import {User} from 'src/app/models/user/user.model';
import {ApiService} from 'src/app/services/admin/api/api.service';
import {AuthenticationService} from 'src/app/services/admin/authentication.service';
import {SelectRoleComponent} from 'src/app/theme/shared/components/modal/admin/select-role/select-role.component';
import {UiModalComponent} from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import {ToastrComponent} from '../../share/toastr/toastr.component';


@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss'],
    providers: [ToastrComponent, UiModalComponent, SelectRoleComponent]
})
export class SignInComponent implements OnInit {
    loginForm: FormGroup;

    public loginedUserRoleList: Role[] = [];

    @ViewChild(SelectRoleComponent) private selectRole: SelectRoleComponent;

    constructor(
        private formBuilder: FormBuilder,
        private authecticationService: AuthenticationService,
        private toastr: ToastrComponent,
        private api: ApiService,
        private router: Router,
        // private selectRole: SelectRoleComponent
    ) {
        this.authecticationService.checkIsLogin()
            .subscribe(data => {
                if (!this.api.hasError(data)) {
                    this.authecticationService.setRoles(data.data.roles);
                    this.authecticationService.setLogineduser(data.data.user);
                    this.router.navigate(['dashboard']);
                }
            });
    }

    ngOnInit(): void {
        this.loginForm = this.formBuilder.group({
            phone: [''],
            password: ['']
        });


    }

    get f() {
        return this.loginForm.controls;
    }

    numberChange(event): void {
    }

    setPhone(phone): void {

        this.f.phone.setValue(phone);
    }

    onSubmit(): void {
        var resp = this.authecticationService.login(this.f.phone.value, this.f.password.value)
            .subscribe(data => {
                if (this.api.hasError(data)) {
                    this.toastr.showError('Giriş gerçekleştirilemedi');
                } else {
                    if (data.data.verificationIsStart) {
                        sessionStorage.setItem('phone', this.f.phone.value);
                        this.router.navigate(['verification']);
                    } else {
                        this.toastr.showSuccess('Girişiniz gerçekleştirildi');
                        this.authecticationService.setSession(data.data.token);
                        this.authecticationService.setLogineduser(data.data.user);

                        this.loginedUserRoleList = data.data.roles;
                        this.authecticationService.setRoles(this.loginedUserRoleList);

                        if (this.loginedUserRoleList.length > 1) {
                            this.selectRole.show();

                        } else {
                            this.router.navigateByUrl('/dashboard');
                        }
                    }
                }
            });
    }
}
