import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticatedModule } from 'src/app/models/authenticatedModule/authenticated-module.model';
import { ModuleAuthentication } from 'src/app/models/moduleAuthentication/module-authentication.model';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ModuleService {

  constructor(
    private api: ApiService
  ) { }
  
  setAuthenticationModuleList(dataSet: AuthenticatedModule[]): void{

    sessionStorage.setItem('moduleSet', JSON.stringify(dataSet));

  }

  getAuthenticationModuleList(): AuthenticatedModule[]{

    let list = sessionStorage.getItem('moduleSet');

    if (list != null) {
      
      return JSON.parse(list);

    }else return [];

  }

  checkModuleIsAuthenticated(id: number, authenticateParameter: 'isCreate' | 'isUpdate' | 'isRead' | 'isDelete'): boolean{

    let list = this.getAuthenticationModuleList();

    let result = list.find( (z: AuthenticatedModule) => {
      
      return ((z.id == id) && (z.authentication[authenticateParameter]));

    });
    
    return result !== undefined;

  }

  moduleList(): Observable<any>{
    return this.api.apiRequestAuth(`Admin/moduleCRUD`, {
      action: 'list'
    });
  }
  userAuthenticatedModules(userId: number): Observable<any>{
    return this.api.apiRequestAuth(`Admin/moduleCRUD`, {
      action: 'listUserAuthenticatedModules',
      userId: userId
    });
  }
  setUserModulesAuthentications(userId: number, moduleSet: ModuleAuthentication[]): Observable<any>{

    return this.api.apiRequestAuth(`Admin/moduleCRUD`, {
      action: 'setUserModulesAuthentications',
      userId: userId,
      moduleSet: moduleSet
    });

  }
  listAllWithAuthenticationInfo(): Observable<any>{

    return this.api.apiRequestAuth(`Admin/moduleCRUD`, {
      action: 'listAllWithAuthenticationInfo'
    });
    
  }
}
