import {Injectable} from '@angular/core';
import {Module} from '../../../../models/module/module.model';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../../../../services/admin/authentication.service';
import {UserService} from '../../../../services/admin/user/user.service';

export interface NavigationItem {
    id: string;
    title: string;
    type: 'item' | 'collapse' | 'group';
    translate?: string;
    icon?: string;
    hidden?: boolean;
    url?: string;
    classes?: string;
    exactMatch?: boolean;
    external?: boolean;
    target?: boolean;
    breadcrumbs?: boolean;
    function?: any;
    badge?: {
        title?: string;
        type?: string;
    };
    children?: Navigation[];
}

export interface Navigation extends NavigationItem {
    children?: NavigationItem[];
}

var NavigationItemsGymAdmin = [
    {
        id: 'navigation',
        title: ''
    }
];
// for adminn
var NavigationItems = [
    {
        id: 'navigation',
        title: 'Menü',
        type: 'group',
        icon: 'feather icon-align-left',
        children: [
            {
                id: 'dashboard',
                title: 'Anasayfa',
                type: 'item',
                moduleID: -1,
                url: '/dashboard',
                classes: 'nav-item',
                icon: 'feather icon-home'
            },
            {
                id: 'users',
                moduleID: 1,
                title: 'Kullanıcı Yönetimi',
                type: 'collapse',
                icon: 'feather icon-users',
                children: [
                    {
                        id: 'list',
                        moduleID: Module.USERS,
                        title: 'Kullanıcılar',
                        type: 'item',
                        url: '/users/list'
                    },
                    {
                        id: 'managers',
                        moduleID: Module.MANAGERS,
                        title: 'Yöneticiler',
                        type: 'item',
                        url: '/manager/list'
                    },
                    {
                        id: 'coachApply',
                        moduleID: Module.COACH_APPLY,
                        title: 'Eğitmenlik İstekleri',
                        type: 'item',
                        url: '/users/coach-apply'
                    }
                ]
            },
            {
                id: 'notifications',
                moduleID: Module.NOTIFICATIONS,
                title: 'Bildirimler',
                type: 'collapse',
                icon: 'feather icon-bell',
                children: [
                    {
                        id: 'send',
                        moduleID: Module.SEND_NOTIFICATION,
                        title: 'Bildirim Gönder',
                        type: 'item',
                        url: '/notification/send'
                    },
                    {
                        id: 'ready-notifications',
                        moduleID: Module.SEND_NOTIFICATION,
                        title: 'Hazır Bildirimler',
                        type: 'item',
                        url: '/notification/ready-notifications'
                    },
                    {
                        id: 'list',
                        moduleID: Module.NOTIFICATION_LOGS,
                        title: 'Bildirim Kayıtları',
                        type: 'item',
                        url: '/notification/list'
                    }
                ]
            },
            {
                id: 'exercises-top',
                moduleID: 3,
                title: 'Egzersizler',
                type: 'collapse',
                icon: 'feather icon-menu',
                children: [
                    {
                        id: 'list',
                        moduleID: 4,
                        title: 'Egzersiz Listesi',
                        type: 'item',
                        url: '/exercises/list'
                    },
                    {
                        id: 'tags',
                        moduleID: Module.EXERCISE_TAGS,
                        title: 'Arama Etiketleri',
                        type: 'item',
                        url: '/exercises/tags'
                    }
                    // {
                    //   id: 'body-areas',
                    //   title: 'Vücut Bölgeleri',
                    //   type: 'item',
                    //   url: '/exercises/body-areas'
                    // },
                    // {
                    //   id: 'menu-level-2.2',
                    //   title: 'Menu Level 2.2',
                    //   type: 'collapse',
                    //   children: [
                    //     {
                    //       id: 'menu-level-2.2.1',
                    //       title: 'Menu Level 2.2.1',
                    //       type: 'item',
                    //       url: 'javascript:',
                    //       external: true
                    //     },
                    //     {
                    //       id: 'menu-level-2.2.2',
                    //       title: 'Menu Level 2.2.2',
                    //       type: 'item',
                    //       url: 'javascript:',
                    //       external: true
                    //     }
                    //   ]
                    // }
                ]
            },
            {
                id: 'package-management-top',
                moduleID: Module.PACKAGE_MANAGEMENT,
                title: 'Paket Yönetimi',
                type: 'collapse',
                icon: 'feather icon-menu',
                children: [
                    {
                        id: 'package_management',
                        moduleID: Module.PACKAGE_MANAGEMENT,
                        title: 'Paketler',
                        type: 'item',
                        url: '/package/list'
                    },
                    {
                        id: 'package_tags',
                        moduleID: Module.PACKAGE_MANAGEMENT,
                        title: 'Etiketler',
                        type: 'item',
                        url: '/package/tags'
                    },
                ]
            },
            {
                id: 'comment_management',
                title: 'Yorum Yönetimi',
                type: 'item',
                moduleID: Module.COMMENT_MANAGEMENT,
                url: '/comments/list',
                classes: 'nav-item',
                icon: 'feather icon-message-circle'
            },
            {
                id: 'sport_branches',
                title: 'Spor Branşları',
                type: 'item',
                moduleID: Module.SPORT_BRANCHES,
                url: '/sport-branches/list',
                classes: 'nav-item',
                icon: 'feather icon-box'
            },
            {
                id: 'gyms',
                title: 'Spor Salonları',
                type: 'item',
                moduleID: Module.GYMS,
                url: '/gym/list',
                classes: 'nav-item',
                icon: 'feather icon-layers'
            },
            {
                id: 'poll_answers',
                title: 'Anket Cevapları',
                type: 'item',
                moduleID: Module.POLL_ANSWERS,
                url: '/polls/list',
                classes: 'nav-item',
                icon: 'feather icon-help-circle'
            },
            {
                id: 'subscription',
                moduleID: Module.PAYMENT_PLANS,
                title: 'Abonelikler',
                type: 'collapse',
                icon: 'feather icon-credit-card',
                children: [
                    {
                        id: 'list-payment-plans',
                        moduleID: Module.PAYMENT_PLANS,
                        title: 'Ödeme Planları',
                        type: 'item',
                        url: '/payment-plans/list'
                    }, {
                        id: 'list-subscription',
                        moduleID: Module.PAYMENT_PLANS,
                        title: 'Abonelikler',
                        type: 'item',
                        url: '/subscription/list'
                    }
                ]
            }
        ]
    }
];

@Injectable()
export class NavigationItem {

    constructor(
        private translateService: TranslateService,
        private authService: AuthenticationService
    ) {
    }

    public get() {

        const selectedRole = this.authService.getSelectedRole();


        if (selectedRole.id == UserService.ROLE_ADMIN) {
            return [
                {
                    id: 'navigation',
                    title: 'Menü',
                    type: 'group',
                    icon: 'feather icon-align-left',
                    children: [
                        {
                            id: 'dashboard',
                            title: 'Anasayfa',
                            type: 'item',
                            moduleID: -1,
                            url: '/dashboard',
                            classes: 'nav-item',
                            icon: 'feather icon-home'
                        },
                        {
                            id: 'users',
                            moduleID: 1,
                            title: 'Kullanıcı Yönetimi',
                            type: 'collapse',
                            icon: 'feather icon-users',
                            children: [
                                {
                                    id: 'list',
                                    moduleID: Module.USERS,
                                    title: 'Kullanıcılar',
                                    type: 'item',
                                    url: '/users/list'
                                },
                                {
                                    id: 'managers',
                                    moduleID: Module.MANAGERS,
                                    title: 'Yöneticiler',
                                    type: 'item',
                                    url: '/manager/list'
                                },
                                {
                                    id: 'coachApply',
                                    moduleID: Module.COACH_APPLY,
                                    title: 'Eğitmenlik İstekleri',
                                    type: 'item',
                                    url: '/users/coach-apply'
                                },
                                {
                                    id: 'referralCode',
                                    moduleID: Module.USERS,
                                    title: 'Referans Kodları',
                                    type: 'item',
                                    url: '/users/referral-code-list'
                                }
                            ]
                        },
                        {
                            id: 'notifications',
                            moduleID: Module.NOTIFICATIONS,
                            title: 'Bildirimler',
                            type: 'collapse',
                            icon: 'feather icon-bell',
                            children: [
                                {
                                    id: 'send',
                                    moduleID: Module.SEND_NOTIFICATION,
                                    title: 'Bildirim Gönder',
                                    type: 'item',
                                    url: '/notification/send'
                                },
                                {
                                    id: 'ready-notifications',
                                    moduleID: Module.SEND_NOTIFICATION,
                                    title: 'Hazır Bildirimler',
                                    type: 'item',
                                    url: '/notification/ready-notifications'
                                },
                                {
                                    id: 'list',
                                    moduleID: Module.NOTIFICATION_LOGS,
                                    title: 'Bildirim Kayıtları',
                                    type: 'item',
                                    url: '/notification/list'
                                }
                            ]
                        },
                        {
                            id: 'advertisements',
                            title: 'Reklamalar',
                            type: 'item',
                            moduleID: Module.ADVERTISEMENTS,
                            url: '/advertisements',
                            classes: 'nav-item',
                            icon: 'feather icon-layers'
                        },
                        {
                            id: 'exercises-top',
                            moduleID: 3,
                            title: 'Egzersizler',
                            type: 'collapse',
                            icon: 'feather icon-menu',
                            children: [
                                {
                                    id: 'list',
                                    moduleID: 4,
                                    title: 'Egzersiz Listesi',
                                    type: 'item',
                                    url: '/exercises/list'
                                },
                                {
                                    id: 'tags',
                                    moduleID: Module.EXERCISE_TAGS,
                                    title: 'Arama Etiketleri',
                                    type: 'item',
                                    url: '/exercises/tags'
                                }
                                // {
                                //   id: 'body-areas',
                                //   title: 'Vücut Bölgeleri',
                                //   type: 'item',
                                //   url: '/exercises/body-areas'
                                // },
                                // {
                                //   id: 'menu-level-2.2',
                                //   title: 'Menu Level 2.2',
                                //   type: 'collapse',
                                //   children: [
                                //     {
                                //       id: 'menu-level-2.2.1',
                                //       title: 'Menu Level 2.2.1',
                                //       type: 'item',
                                //       url: 'javascript:',
                                //       external: true
                                //     },
                                //     {
                                //       id: 'menu-level-2.2.2',
                                //       title: 'Menu Level 2.2.2',
                                //       type: 'item',
                                //       url: 'javascript:',
                                //       external: true
                                //     }
                                //   ]
                                // }
                            ]
                        },
                        {
                            id: 'package_management',
                            moduleID: Module.PACKAGE_MANAGEMENT,
                            title: 'Paket Yönetimi',
                            type: 'collapse',
                            icon: 'feather icon-box',
                            children: [
                                {
                                    id: 'package-list',
                                    moduleID: Module.PACKAGE_MANAGEMENT,
                                    title: 'Paketler',
                                    type: 'item',
                                    url: '/package/list'
                                },
                                {
                                    id: 'package-tag-groups',
                                    moduleID: Module.PACKAGE_MANAGEMENT,
                                    title: 'Etiket Grupları',
                                    type: 'item',
                                    url: '/package/tag-groups'
                                },
                                {
                                    id: 'package-tags',
                                    moduleID: Module.PACKAGE_MANAGEMENT,
                                    title: 'Etiketler',
                                    type: 'item',
                                    url: '/package/tags'
                                }
                            ]
                        },
                        {
                            id: 'comment_management',
                            title: 'Yorum Yönetimi',
                            type: 'item',
                            moduleID: Module.COMMENT_MANAGEMENT,
                            url: '/comments/list',
                            classes: 'nav-item',
                            icon: 'feather icon-message-circle'
                        },
                        {
                            id: 'sport_branches',
                            title: 'Spor Branşları',
                            type: 'item',
                            moduleID: Module.SPORT_BRANCHES,
                            url: '/sport-branches/list',
                            classes: 'nav-item',
                            icon: 'feather icon-box'
                        },
                        {
                            id: 'gyms',
                            title: 'Spor Salonları',
                            type: 'item',
                            moduleID: Module.GYMS,
                            url: '/gym/list',
                            classes: 'nav-item',
                            icon: 'feather icon-layers'
                        },
                        {
                            id: 'poll_answers',
                            title: 'Anket Cevapları',
                            type: 'item',
                            moduleID: Module.POLL_ANSWERS,
                            url: '/polls/list',
                            classes: 'nav-item',
                            icon: 'feather icon-help-circle'
                        },
                        {
                            id: 'subscription',
                            moduleID: Module.PAYMENT_PLANS,
                            title: 'Abonelikler',
                            type: 'collapse',
                            icon: 'feather icon-credit-card',
                            children: [
                                {
                                    id: 'list-payment-plans',
                                    moduleID: Module.PAYMENT_PLANS,
                                    title: 'Ödeme Planları',
                                    type: 'item',
                                    url: '/payment-plans/list'
                                }, {
                                    id: 'list-subscription',
                                    moduleID: Module.PAYMENT_PLANS,
                                    title: 'Abonelikler',
                                    type: 'item',
                                    url: '/subscription/list'
                                }
                            ]
                        }
                    ]
                }
            ];
        } else {

            return [
                {
                    id: 'navigation',
                    title: 'Menü',
                    type: 'group',
                    icon: 'feather icon-align-left',
                    children: [
                        {
                            id: 'dashboard',
                            title: 'Anasayfa',
                            type: 'item',
                            moduleID: -1,
                            url: '/dashboard',
                            classes: 'nav-item',
                            icon: 'feather icon-home'
                        },
                        {
                            id: 'coachs',
                            title: 'Eğitmenler',
                            type: 'item',
                            moduleID: Module.COACHS,
                            url: '/gym/gym-user-list/coach',
                            classes: 'nav-item',
                            icon: 'feather icon-user'
                        },
                        {
                            id: 'users',
                            title: 'Danışanlar',
                            type: 'item',
                            moduleID: Module.COACHS,
                            url: '/gym/gym-user-list/user',
                            classes: 'nav-item',
                            icon: 'feather icon-user'
                        },
                        {
                            id: 'company_info',
                            title: 'Firma Bilgileri',
                            type: 'item',
                            moduleID: Module.COMPANY_INFO,
                            url: '/gym/gym',
                            classes: 'nav-item',
                            icon: 'feather icon-briefcase'
                        },
                        {
                            id: 'comments',
                            title: 'Yorumlar',
                            type: 'item',
                            moduleID: -1,
                            url: '/comments/list',
                            classes: 'nav-item',
                            icon: 'feather icon-message-circle'
                        },
                        {
                            id: 'packages',
                            title: 'Paketler',
                            type: 'item',
                            moduleID: -1,
                            url: '/package/list',
                            classes: 'nav-item',
                            icon: 'feather icon-box'
                        }
                    ]
                }
            ];
        }


    }

    public spliceItem(index: number, topIndex: number): void {

        let list = this.get();
        if (topIndex == -1) {

            list[0].children.splice(index, 1);

        } else {

            if (list[0].children[topIndex]['children'] != undefined) {
                list[0].children[topIndex].children.splice(index, 1);
            }


        }

    }


}
