<div class="auth-wrapper">
    <div class="auth-content">
        <div class="card" style="background: black; color: white;" >
            <div class="row align-items-center text-center">
                <div class="col-md-12">
                    <div class="card-body">
                        <img src="assets/images/sportistlogo.png" alt="" class="img-fluid mb-4">
                        <div class="row">
                            <div class="col-md-12 mb-2">
                               {{ 'verify_your_phone_number' | translate }}
                                <br/>
                                <small>
                                    {{ phone }} {{ 'enter_otp_code' | translate }}
                                </small>
                            </div>
                            <div class="col-md-12 mb-2">
                                <input class="form-control" [(ngModel)]="code" [ngModelOptions]="{standalone: true}" />
                            </div>
                            <div class="col-md-12">
                                <button class="btn btn-block btn-primary" type="button" (click)="verify()" >
                                    {{ 'verify' | translate }}
                                </button>
                            </div>

                            <div class="col-md-12 mt-2">
                                <small>
                                    <a [routerLink]="['/sign-in']" >
                                        Giriş sayfasına geri dön
                                    </a>
                                </small>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
