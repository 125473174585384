import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'age'
})
export class AgePipe implements PipeTransform {

  transform(value: string): string {
    const currentYear = (new Date()).getFullYear();
    const birthDay = new Date(value);

    return (currentYear - birthDay.getFullYear()) + '';

  }

}
