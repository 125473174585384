import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {ApiService} from './api/api.service';
import {User} from 'src/app/models/user/user.model';
import {Role} from '../../models/role/role.model';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    public user: User;

    constructor(
        private api: ApiService,
        private cookieService: CookieService
    ) {
    }

    public login(phone?: string, password?: string): Observable<any> {
        return this.api.apiRequest('Authentication/adminLogin', {
            action: 'login',
            phone,
            password
        });

    }

    public verification(code: string, phone: string): Observable<any> {

        return this.api.apiRequest('Authentication/verification', {
            phone,
            code
        });

    }

    public checkIsLogin(): Observable<any> {
        return this.api.apiRequestGet('Authentication/adminLogin');
    }

    public sendForgetPasswordCode(phone: string): Observable<any> {

        return this.api.apiRequest('Authentication/forgotPassword', {
            phone
        });

    }

    public resetPassword(phone: string, code: string, password: string): Observable<any> {

        return this.api.apiRequest('Authentication/verifyForgotPassword', {
            phone,
            code,
            password
        });

    }

    public setSession(token: string): void {
        this.cookieService.set('token', token);
    }

    public getSession(): string {
        return this.cookieService.get('token');
    }

    public setLogineduser(userInfo: User): void {

        this.user = userInfo;


    }

    public getLoginedUser(): User {

        return this.user;
    }

    public removeSession(): void {
        this.cookieService.delete('token');
    }

    public setRole(roleId: number): Observable<any> {

        return this.api.apiRequestPatch('Authentication/adminLogin', {
            roleId
        });

    }

    public setRoles(roles: Role[]): void {

        sessionStorage.setItem('roles', JSON.stringify(roles));

    }

    public getRoles(): Role[] {

        return JSON.parse(sessionStorage.getItem('roles'));

    }

    public setSelectedRoleSession(roleId: number): void {

        const roles: Role[] = this.getRoles();
        const setRoles: Role[] = roles.map(d => {
            if (d.id === roleId) {
                d.isSelected = true;
            }
            return d;
        });

        this.setRoles(setRoles);
    }

    public getSelectedRole(): Role {

        const roles = this.getRoles();
        return roles.find(d => {
            if (d.isSelected) {
                return d;
            }
        });

    }
}
