import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProfilePhoto, User} from '../../../models/user/user.model';
import {GeneralService} from '../../../services/admin/general/general.service';

@Component({
    selector: 'app-select-user-search',
    templateUrl: './select-user-search.component.html',
    styleUrls: ['./select-user-search.component.scss']
})
export class SelectUserSearchComponent implements OnInit {

    @Input() public labelText: string;
    @Input() public search = '';
    @Input() public users: User[] = [];
    @Input() public selectedUser?: User;

    @Output() public searchAction = new EventEmitter();
    @Output() public userSelected = new EventEmitter();

    constructor(
        private generalService: GeneralService
    ) { }

    ngOnInit(): void {
    }

    searchUsers(): void{

        this.searchAction.emit({
            search: this.search
        });

    }
    public profilePhoto(image: ProfilePhoto): string{

        return this.generalService.getUserProfilePhoto(image);

    }
    setSelectedUser(user: User): void{

        this.selectedUser = user;
        this.userSelected.emit(user);
    }

    removeChoose(): void{

        this.selectedUser = undefined;
        this.userSelected.emit(undefined);

    }
}
