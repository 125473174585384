import {Component, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/models/user/user.model';
import { AuthenticationService } from 'src/app/services/admin/authentication.service';
import {Role} from '../../../../../models/role/role.model';
import {SelectRoleComponent} from '../../../../shared/components/modal/admin/select-role/select-role.component';
import {UiModalComponent} from '../../../../shared/components/modal/ui-modal/ui-modal.component';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig, SelectRoleComponent, UiModalComponent]
})
export class NavRightComponent implements OnInit {

    @ViewChild(SelectRoleComponent) public selectRole: SelectRoleComponent;


  public user: User;
  public profilePhoto: string;
  private authentication: AuthenticationService;
  private router: Router;
  public roles: Role[] = [];

  constructor(
    authentication: AuthenticationService,
    router: Router
  ) {
      this.authentication = authentication;
      this.router = router;
      this.user = authentication.getLoginedUser();
      this.profilePhoto = this.getImageUrl();
   }

  ngOnInit() {

      this.roles = this.authentication.getRoles();

  }

  getImageUrl(): string {
    if (this.user.image != null) {
      return this.user.image.url;
    }else return `assets/images/default-profile-pic.jpeg`;
  }
  public logout(){
    
    this.authentication.removeSession();
    this.router.navigate(['sign-in']);
    
  }

  public showSelectRoleModal(): void{

      this.selectRole.show();

  }
}
