import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {SignInComponent} from './admin/authentication/sign-in/sign-in.component';
import {DashboardComponent} from './admin/dashboard/dashboard/dashboard.component';
import {LoginGuardGuard} from './login-guard.guard';
import {ApiService} from './services/admin/api/api.service';
import {AuthenticationService} from './services/admin/authentication.service';
import {AdminComponent} from './theme/layout/admin/admin.component';
import {AuthComponent} from './theme/layout/auth/auth.component';
import {VerificationComponent} from './admin/authentication/verification/verification.component';
import {ForgetPasswordComponent} from './admin/authentication/forget-password/forget-password.component';

const routes: Routes = [
    {
        path: 'sign-in',
        component: SignInComponent
    },
    {
        path: 'verification',
        component: VerificationComponent
    },
    {
        path: '',
        redirectTo: 'sign-in',
        pathMatch: 'full'
    },
    {
        path: 'forget-password',
        component: ForgetPasswordComponent
    },
    {
        path: '',
        component: AdminComponent,
        canActivate: [LoginGuardGuard],
        children: [
            {
                path: 'dashboard',

                loadChildren: () => import('./admin/dashboard/dashboard/dashboard.module').then(module => module.DashboardModule)
            },
            {
                path: 'users',
                loadChildren: () => import('./admin/users/users.module').then(module => module.UsersModule)
            },
            {
                path: 'manager',
                loadChildren: () => import('./admin/manager/manager.module').then(module => module.ManagerModule)
            },
            {
                path: 'gym',
                loadChildren: () => import('./admin/gym/gym.module').then(module => module.GymModule)
            },
            {
                path: 'subscription',
                loadChildren: () => import('./admin/subscription/subscription.module').then(module => module.SubscriptionModule)
            },
            {
                path: 'sport-branches',
                loadChildren: () => import('./admin/sport-branches/sport-branches.module').then(module => module.SportBranchesModule)
            },
            {
                path: 'comments',
                loadChildren: () => import('./admin/comments/comments.module').then(module => module.CommentsModule)
            },
            {
                path: 'package',
                loadChildren: () => import('./admin/package/package.module').then(module => module.PackageModule)
            },
            {
                path: 'polls',
                loadChildren: () => import('./admin/polls/polls.module').then(module => module.PollsModule)
            },
            {
                path: 'notification',
                loadChildren: () => import('./admin/notification/notification.module').then(module => module.NotificationModule)
            },
            {
                path: 'relationship',
                loadChildren: () => import('./admin/relationship/relationship.module').then(module => module.RelationshipModule)
            },
            {
                path: 'exercises',
                loadChildren: () => import('./admin/exercises/exercises.module').then(module => module.ExercisesModule)
            },
            {
                path: 'account',
                loadChildren: () => import('./admin/account/account.module').then(module => module.AccountModule)
            },
            {
                path: 'payment-plans',
                loadChildren: () => import('./admin/payment-plans/payment-plans.module').then(module => module.PaymentPlansModule)
            },
            {
                path: 'advertisements',
                loadChildren: () => import('./admin/advertisement/advertisement.module').then(module => module.AdvertisementModule)
            },
            {
                path: 'sample-page',
                loadChildren: () => import('./demo/pages/sample-page/sample-page.module').then(module => module.SamplePageModule)
            }
        ]
    },
    {
        path: '',
        component: AuthComponent,
        children: [
            // {
            //   path: 'login',
            //   loadChildren: () => import()
            // }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy', initialNavigation: 'enabled'})],
    exports: [RouterModule],
    providers: [ApiService, AuthenticationService]
})
export class AppRoutingModule {
}
