import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../../services/admin/api/api.service';
import {AuthenticationService} from '../../../services/admin/authentication.service';
import {ToastrComponent} from '../../share/toastr/toastr.component';
import {Router} from '@angular/router';

@Component({
    selector: 'app-verification',
    templateUrl: './verification.component.html',
    styleUrls: ['./verification.component.scss'],
    providers: [ToastrComponent]
})
export class VerificationComponent implements OnInit {

    public code = '';
    public phone = '';

    constructor(
        private api: ApiService,
        private authenticationService: AuthenticationService,
        private toastr: ToastrComponent,
        private router: Router
    ) {

        this.phone = sessionStorage.getItem('phone');

    }

    ngOnInit(): void {
    }

    public verify(): void{

        this.authenticationService.verification(this.code, this.phone)
            .subscribe(data => {

                if (! this.api.hasError(data)){
                    this.toastr.showSuccess(data.result.message);
                    this.router.navigate(['sign-in']);
                }else{
                    this.toastr.showError(data.result.message);
                }

            });

    }


}
