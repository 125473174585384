import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/services/admin/api/api.service';
import { ModuleService } from 'src/app/services/admin/module/module.service';
import { NextConfig } from '../../../../app-config';
import { NavigationItem } from './navigation';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../../../../services/admin/authentication.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  public windowWidth: number;
  public nextConfig: any;
  @Output() onNavMobCollapse = new EventEmitter();

  constructor(
    private api: ApiService,
    private moduleService: ModuleService,
    private translateService: TranslateService,
    private authService: AuthenticationService
  ) {
    this.nextConfig = NextConfig.config;
    this.windowWidth = window.innerWidth;

    this.moduleService.listAllWithAuthenticationInfo()
      .subscribe(data => {

        if (!this.api.hasError(data)) {

          this.moduleService.setAuthenticationModuleList(data.data.list);

          (data.data.list).forEach(item => {
            
            if ((item.showMenu) && (!item.authentication.isRead)) {
              
              this.setModuleShowing(item.id);
              
            }

          });

        }

      })

  }
  setModuleShowing(moduleId: number): void{
    let navItems = (new NavigationItem(this.translateService, this.authService)).get();
    
    navItems[0].children.find( (z, index) => {

      if (z.moduleID == moduleId) {
        
        (new NavigationItem(this.translateService, this.authService)).spliceItem(index, -1);
        return index;
      }

      if (typeof z.children != 'undefined') {
        
        z.children.find( (c, subIndex) => {
          if (c.moduleID == moduleId) {
            
            (new NavigationItem(this.translateService, this.authService)).spliceItem(subIndex, index);
            return subIndex;
          }
        }  )

      }

    } )


  }
  ngOnInit() {



  }

  navMobCollapse() {
    if (this.windowWidth < 992) {
      this.onNavMobCollapse.emit();
    }
  }
}
