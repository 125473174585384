export class Module {

  constructor(
    public id: number,
    public parentId: number,
    public name: string,
    public showMenu: boolean,
    public icon: string,
    public hasCrud: boolean,
    public subs: Module[]
  ){}

  public static MANAGERS = 6;
  public static COACH_APPLY = 5;
  public static USERS = 2;
  public static NOTIFICATIONS = 7;
  public static SEND_NOTIFICATION = 8;
  public static NOTIFICATION_LOGS = 9;
  public static GYMS = 10;
  public static PACKAGE_MANAGEMENT = 11;
  public static POLL_ANSWERS = 12;
  public static EXERCISE_TAGS = 13;
  public static SPORT_BRANCHES = 14;
  public static COMMENT_MANAGEMENT = 15;
  public static PAYMENT_PLANS = 17;
  public static COACHS = 16;
  public static COMPANY_INFO = 18;
  public static ADVERTISEMENTS = 20;
}
