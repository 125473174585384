import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UiModalComponent} from '../../ui-modal/ui-modal.component';
import {Role} from '../../../../../../models/role/role.model';
import {ApiService} from '../../../../../../services/admin/api/api.service';
import {AuthenticationService} from '../../../../../../services/admin/authentication.service';
import {Route, Router} from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-select-role',
  templateUrl: './select-role.component.html',
  styleUrls: ['./select-role.component.scss'],
  providers: [UiModalComponent]
})
export class SelectRoleComponent implements OnInit {

  @Input() public roles: Role[] = [];

  @ViewChild(UiModalComponent) private modal: UiModalComponent;

  constructor(
      private api: ApiService,
      private authService: AuthenticationService,
      private router: Router,
      private location: Location
  ) { }

  ngOnInit(): void {
  }

  show(): void{
    this.modal.show();
  }

  public setSelectedRole(id: number): void{

    this.roles = this.roles.map(d => {

      d.isSelected = (d.id === id);
      return d;

    });

  }

  public set(): void{

    const selectedRole = this.roles.find(d => {
      if (d.isSelected){
        return d;
      }
    });

    this.authService.setRole(selectedRole.id)
        .subscribe(data => {

          if (! this.api.hasError(data)){

            this.authService.setSession(data.data.token);
            this.authService.setSelectedRoleSession(selectedRole.id);
            this.router.navigateByUrl('/dashboard');
            window.location.reload();
          }

        });

  }
}
