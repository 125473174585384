import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CountryISO, NgxIntlTelInputComponent} from 'ngx-intl-tel-input';

@Component({
    selector: 'app-phone-input',
    templateUrl: './phone-input.component.html',
    styleUrls: ['./phone-input.component.scss']
})
export class PhoneInputComponent implements OnInit {

    @ViewChild('telInput') public telInputComponent: NgxIntlTelInputComponent;

    @Input() public labelText = '';
    @Input() public inputId = '';
    @Input() public value = '';

    @Output() formattedValue = new EventEmitter();

    public phone?: { countryCode?: string, dialCode?: string, e164Number?: string, internationalNumber?: string, nationalNumber?: string, number?: string };

    public cIso: CountryISO[] = [CountryISO.Turkey, CountryISO.UnitedStates, CountryISO.Ukraine];
    public defaultIso: CountryISO = CountryISO.Turkey;

    constructor(
    ) {
    }

    ngOnInit(): void {
    }

    public valueChanged(): any {

        if (this.phone) {
            this.formattedValue.emit(this.phone.e164Number.replace('+', ''));

        }

    }
}
