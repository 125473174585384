import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {TranslateLoader, TranslateModule, TranslatePipe, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from './theme/shared/shared.module';

import {AppComponent} from './app.component';
import {AdminComponent} from './theme/layout/admin/admin.component';
import {AuthComponent} from './theme/layout/auth/auth.component';
import {NavigationComponent} from './theme/layout/admin/navigation/navigation.component';
import {NavContentComponent} from './theme/layout/admin/navigation/nav-content/nav-content.component';
import {NavGroupComponent} from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import {NavCollapseComponent} from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import {NavItemComponent} from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import {NavBarComponent} from './theme/layout/admin/nav-bar/nav-bar.component';
import {NavLeftComponent} from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import {NavSearchComponent} from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import {NavRightComponent} from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import {ConfigurationComponent} from './theme/layout/admin/configuration/configuration.component';

import {ToggleFullScreenDirective} from './theme/shared/full-screen/toggle-full-screen';

/* Menu Items */
import {NavigationItem} from './theme/layout/admin/navigation/navigation';
import {
    NgbButtonsModule,
    NgbDropdownModule,
    NgbTabsetModule,
    NgbTooltipModule,
    NgbModule,
    NgbPagination,
    NgbPaginationModule
} from '@ng-bootstrap/ng-bootstrap';
import {SignInComponent} from './admin/authentication/sign-in/sign-in.component';

import {HttpClientModule, HttpClient} from '@angular/common/http';

import {ToastrModule} from 'ngx-toastr';
import {ToastrComponent} from './admin/share/toastr/toastr.component';
import {DashboardComponent} from './admin/dashboard/dashboard/dashboard.component';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {LTranslateService} from './services/lTranslate/l-translate.service';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {VerificationComponent} from './admin/authentication/verification/verification.component';
import {ForgetPasswordComponent} from './admin/authentication/forget-password/forget-password.component';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {CookieService} from 'ngx-cookie-service';


export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, '../assets/i18n/', '.json');
}

(window as any).global = window;

@NgModule({
    declarations: [
        AppComponent,
        AdminComponent,
        AuthComponent,
        NavigationComponent,
        NavContentComponent,
        NavGroupComponent,
        NavCollapseComponent,
        NavItemComponent,
        NavBarComponent,
        NavLeftComponent,
        NavSearchComponent,
        NavRightComponent,
        ConfigurationComponent,
        ToggleFullScreenDirective,
        SignInComponent,
        VerificationComponent,
        ForgetPasswordComponent,
        ToastrComponent
    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        AppRoutingModule,
        BrowserAnimationsModule,
        SharedModule,
        NgbDropdownModule,
        NgbTooltipModule,
        NgbButtonsModule,
        NgbTabsetModule,
        HttpClientModule,
        ToastrModule.forRoot(), // ToastrModule added
        SweetAlert2Module.forRoot(),
        NgbPaginationModule,
        NgxChartsModule
    ],
    exports: [TranslateModule, TranslatePipe],
    providers: [NavigationItem, HttpClientModule, CookieService],
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor(private translate: LTranslateService, private translateService: TranslateService) {

        this.translateService.addLangs(['tr', 'en']);
        this.translateService.setDefaultLang('tr');
        this.translateService.use('tr');
    }

}
