<app-ui-modal #modalDefault>
    <div class="app-modal-header">
      <h5 class="modal-title">
          {{ 'select_role' | translate }}
      </h5>
      <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="modalDefault.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">

        <div class="row m-2">
            <div *ngFor="let role of roles;" [ngClass]="(role.isSelected) ? 'col-md-12 role-item selected' : 'col-md-12 role-item'" (click)="setSelectedRole(role.id)" >
                {{ role.name }}
            </div>

            <div class="col-md-12 mt-2">
                <button type="button" class="btn btn-success btn-block btn-sm" (click)="set()" >
                    {{ 'apply_choose_and_continue' | translate }}
                </button>
            </div>
        </div>


    </div>
</app-ui-modal>
