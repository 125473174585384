<label>
    {{ labelText }}
</label>
<ng-template [ngIf]="! selectedUser" >
    <input type="text"  [(ngModel)]="search" (keyup)="searchUsers()"  class="form-control form-control-sm">
</ng-template>


<ng-template [ngIf]="(users.length > 0) && (! selectedUser)" >
    <div class="col-md-12 mb-2" *ngFor="let u of users;">
        <a href="javascript:;"  (click)="setSelectedUser(u)">
            <img [src]="profilePhoto(u.image)" style="    width: 45px;
                        height: 45px;
                        object-fit: cover;
                        border-radius: 50%;" alt="">
            {{ u.name }} {{ u.surname }}
        </a>
    </div>
</ng-template>
<ng-template [ngIf]="selectedUser" >
    <a href="javascript:;">
        <img [src]="profilePhoto(selectedUser.image)" style="    width: 45px;
                        height: 45px;
                        object-fit: cover;
                        border-radius: 50%;" alt="">
        {{ selectedUser.name }} {{ selectedUser.surname }}
    </a>
    <a class="link text-muted" (click)="removeChoose()" >
        <small>{{ 'remove_choose' | translate }}</small>
    </a>
</ng-template>
