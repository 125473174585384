import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../../models/user/user.model';

@Component({
    selector: 'app-user-block',
    templateUrl: './user-block.component.html',
    styleUrls: ['./user-block.component.scss']
})
export class UserBlockComponent implements OnInit {

    @Input() user: User;
    constructor() {
    }

    ngOnInit(): void {
    }

}
