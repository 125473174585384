import { Gym } from "../gym/gym.model";
import { SportBranch } from "../sportBranch/sport-branch.model";
import { StudentLevel } from "../studentLevel/student-level.model";
import { User } from "../user/user.model";
import {PackageTag} from './packageTag/package-tag.model';

export class Package {
  
  public static types: { name: number, value: string}[] = [
    {
      name: -1,
      value: 'Tümü'
    },
    {
      name: 0,
      value: 'Herkese Açık'
    },
    {
      name: 1,
      value: 'Gizli'
    }
  ];
  public static publishTypes: { name: number, value: string, showValue: string, color: string}[] = [
    {

      name: -1,
      value: 'Tümü',
      showValue: 'Tümü',
      color: 'dark'
    },
    {
      name: 0,
      value: 'Onay Bekleyenler',
      showValue: 'Onay Bekliyor',
      color: 'warning'
    },
    {
      name: 1,
      value: 'Onaylananlar',
      showValue: 'Onaylandı',
      color: 'success'
    },
    {
      name: 2,
      value: 'Reddedilenler',
      showValue: 'Reddedildi',
      color: 'danger'
    }
  ];
  constructor(
    public id: number,
    public title: string, 
    public studentLevel: StudentLevel,
    public type: number,
    public user: User,
    public lang: string,
    public about?: string, 
    public image?: string,
    public likeRate?: number, 
    public starRate?: number, 
    public sportBranch?: SportBranch[],
    public publishType?: number,
    public createdAt?: string,
    public isPrivate?: boolean,
    public gym?: Gym,
    public isLocked: boolean = false,
    public useCount: number = 0,
    public tags: PackageTag[] = [],
    public isPremium: boolean = false,
    public averageTime: string = '',
    public difficulty: number = 0
  ){}

}
