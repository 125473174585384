import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbCalendar, NgbDate, NgbDateStruct, NgbInputDatepickerConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
    providers: [NgbInputDatepickerConfig]
})
export class DatePickerComponent implements OnInit {

    @Input() minDate?: Date = new Date();
    @Output() dateChanged = new EventEmitter();

    model: NgbDateStruct;
    date: { year: number, month: number };

    constructor(
        private config: NgbInputDatepickerConfig,
        private calendar: NgbCalendar
    ) {

        if (this.minDate) {

            this.config.minDate = {

                year: this.minDate.getFullYear(),
                month: this.minDate.getMonth() + 1,
                day: this.minDate.getDate()

            };

        }

    }

    ngOnInit(): void {
    }

    public dateSelected(date: NgbDate): void {

        const selected = new Date(date.year, date.month, date.day);
        this.dateChanged.emit(selected);

    }

}
