import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  @Input()
  size: string = 'medium';

  @Input()
  name: string = 'spinner';

  @Input()
  fullScreen: boolean = false;

  public background: string = 'rgba(0, 0, 0, 0.0)';
  constructor(
    private spinner: NgxSpinnerService
  ) { 

    if(this.fullScreen) this.background = 'rgba(0, 0, 0, 0.3)';

  }

  ngOnInit(): void {
  }

  public show(name: string = this.name): void{

    this.spinner.show(name);

  }

  public hide(name: string = this.name): void{

    this.spinner.hide(name);

  }

}
