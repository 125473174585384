import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {Response} from 'src/app/models/api/response.model';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})

export class ApiService {

    constructor(
        private http: HttpClient,
        private cookieService: CookieService
    ) {
    }

    private getRequestHeaders(isJson: boolean = false): HttpHeaders {

        return new HttpHeaders({
            Authorization: 'Bearer ' + this.cookieService.get('token'),
            Version: '3'
        });

    }

    public apiRequest(service: string, dataset?: object, headers: object = {}): Observable<any> {
        const httpHeaderObj = {};
        // tslint:disable-next-line:forin
        for (const key in headers) {
            httpHeaderObj[key] = headers[key];
        }

        const requestHeaders = new HttpHeaders(httpHeaderObj);
        return this.http.post<Response>(`api/${service}`,
            dataset, {
                headers: requestHeaders
            });
    }

    public apiRequestAuth(service: string, dataset?: object): Observable<any> {


        return this.http.post<Response>(`api/${service}`,
            dataset, {
                headers: this.getRequestHeaders()
            });
    }

    public apiRequestPut(service: string, dataset?: object): Observable<any> {

        return this.http.put(`api/${service}`, dataset, {
            headers: this.getRequestHeaders()
        });

    }

    public apiRequestDelete(service: string, dataset: object = {}): Observable<any> {

        return this.http.request('delete', `api/${service}`, {
            headers: this.getRequestHeaders(true),
            body: dataset
        });

    }

    public apiRequestGet(service: string, dataset: object = {}): Observable<any> {

        const headers = this.getRequestHeaders();
        const params = Object.keys(dataset).map(key => `${key}=${encodeURIComponent(dataset[key])}`).join('&');

        headers.set('params', params);

        return this.http.get(`api/${service}?${params}`, {headers});

    }

    public apiRequestPatch(service: string, dataset: object = {}): Observable<any> {

        return this.http.patch(`api/${service}`, dataset, {
            headers: this.getRequestHeaders()
        });

    }


    public hasError(response: Response): boolean {
        return (!response.result.isSuccess);
    }
}
