<div class="d-inline-block align-middle">
    <ng-template [ngIf]="user?.image?.url">
        <img [src]="user.image.url" alt="user image"
             class="img-radius align-top m-r-15"
             style="width:40px; height: 40px; object-fit: cover;">
    </ng-template>

    <div class="d-inline-block">
        <h6 class="m-b-0">{{ user.name }} {{ user.surname }}</h6>
        <p class="m-b-0">{{ user.phone }}
            <ng-template [ngIf]="user.verification">
                <i class="fa fa-check text-success"></i>
            </ng-template>
            <ng-template [ngIf]="user.isGuest">
                <i class="fa fa-user"></i>
            </ng-template>
        </p>
        <ng-template [ngIf]="user.hasPremium">
            <span class="badge badge-primary">
              {{ 'premium' | translate }}
            </span>
        </ng-template>
    </div>
</div>
