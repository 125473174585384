<ng-template [ngIf]="labelText.length > 0">
    <label>
        {{ labelText | translate }}
    </label>
</ng-template>

<ngx-intl-tel-input
        #telInput
        [cssClass]="'custom'"
        [preferredCountries]="cIso"
        [enableAutoCountrySelect]="false"
        [enablePlaceholder]="true"
        [searchCountryFlag]="false"
        [searchCountryField]="[]"
        [selectFirstCountry]="false"
        [selectedCountryISO]="defaultIso"
        [maxLength]="15"
        [phoneValidation]="true"
        [inputId]="'phone'"
        name="phone"
        [(ngModel)]="phone"
        (keyup)="valueChanged()"
></ngx-intl-tel-input>
