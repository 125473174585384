import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../services/admin/authentication.service';
import {ApiService} from '../../../services/admin/api/api.service';
import {ToastrComponent} from '../../share/toastr/toastr.component';
import {Router} from '@angular/router';

@Component({
    selector: 'app-forget-password',
    templateUrl: './forget-password.component.html',
    styleUrls: ['./forget-password.component.scss'],
    providers: [ToastrComponent]
})
export class ForgetPasswordComponent implements OnInit {

    public step = 1;
    public phone = '';
    public code = '';
    public newPassword = '';
    public newPasswordAgain = '';

    public hasError = false;

    constructor(
        private authenticationService: AuthenticationService,
        private api: ApiService,
        private toastr: ToastrComponent,
        private router: Router
    ) {
    }

    ngOnInit(): void {
    }

    private setStep(step: number): void{
        this.step = step;
    }

    verify(): void{

        if ( this.checkNewPasswordAgain() && this.checkNewPasswordComplex() ){

            this.hasError = false;

            this.authenticationService.resetPassword(this.phone, this.code, this.newPassword)
                .subscribe(data => {

                    if (! this.api.hasError(data)){

                        this.toastr.showSuccess(data.result.message);
                        this.setStep(1);
                        this.router.navigateByUrl('/sign-in');
                    }else{

                        this.toastr.showError(data.result.message);

                    }

                });

        }else{

            this.hasError = true;

        }

    }

    checkNewPasswordAgain(): boolean {

        return (this.newPassword === this.newPasswordAgain);

    }

    setPhone(phone: string): void{

        this.phone = phone;

    }

    checkNewPasswordComplex(): boolean {

        const newPassword = this.newPassword;

        const isLong = (newPassword.length >= 6);
        const hasContainNumber = /\d/.test(newPassword);
        const hasContainLetter = /[a-z]/i.test(newPassword);

        return (isLong && hasContainLetter && hasContainNumber);
    }

    sendForgetPasswordCode(): void {

        this.authenticationService.sendForgetPasswordCode(this.phone)
            .subscribe(data => {

                if (!this.api.hasError(data)) {

                    this.toastr.showSuccess(data.result.message);
                    this.setStep(2);

                } else {

                    this.toastr.showError(data.result.message);

                }

            });

    }
}
