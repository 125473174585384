<app-ui-modal #modalDefault>
    <div class="app-modal-header">
        <h5 class="modal-title">
            {{ 'user_info' | translate }}
        </h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
                (click)="modalDefault.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="row">

            <ng-template [ngIf]="user" >
                <div class="col-md-12 text-center">
                    <img style="width: 100px; height: 100px; object-fit: contain; border-radius: 50%;" [src]="userProfile()" />
                </div>

                <div class="col-md-12">
                    <ng-template [ngIf]="user.hasPremium" >
                            <span class="badge badge-primary" >
                              {{ 'premium' | translate }}
                            </span>
                    </ng-template>
                    <strong>
                        {{ 'name_surname' | translate }}
                    </strong> {{ user.name }} {{ user.surname }} <br/>

                    <strong>
                        {{ 'age' | translate }}
                    </strong> {{ user.date_birth | age }} <br/>

                    <strong>
                        {{ 'gender' | translate }}
                    </strong> {{ genderText(user.gender) | translate }} <br/>

                    <strong>Telefon Numarası: </strong> {{ user.phone }}
                    <ng-template [ngIf]="user.verification" >
                        <i class="fa fa-check text-success"></i>
                    </ng-template>
                    <br />

                    <ng-template [ngIf]="!user.isCoach">
                        <strong>Seviye: </strong> {{ user.studentLevel.name }} <br />
                    </ng-template>

                    <strong>Hakkında: </strong>
                    <ng-template [ngIf]="user.about !== null">{{ user.about }}</ng-template> <br />
                </div>
            </ng-template>


        </div>
    </div>
</app-ui-modal>
