<div class="auth-wrapper">
    <div class="auth-content">
        <div class="card" style="background: black; color: white;" >
            <div class="row align-items-center text-center">
                <div class="col-md-12">
                    <div class="card-body">
                        <img src="assets/images/sportistlogo.png" alt="" class="img-fluid mb-4">
                        <div class="row">
                            <div class="col-md-12 mb-2">
                                {{ 'forget_password' | translate }}
                                <br/>
                            </div>

                            <ng-template [ngIf]="step == 1" >

                                <div class="col-md-12">
                                    <small>
                                        {{ 'enter_your_phone_for_fp' | translate }}
                                    </small>
                                </div>

                                <div class="col-md-12 mt-2 mb-2">
                                    <app-phone-input (formattedValue)="setPhone($event)" [labelText]="'your_phone_number'" ></app-phone-input>

                                </div>

                                <div class="col-md-12 mt-2">
                                    <button class="btn btn-primary btn-block" (click)="sendForgetPasswordCode()">
                                        {{ 'send_verification_code' | translate }}
                                    </button>
                                </div>
                            </ng-template>

                            <ng-template [ngIf]="step == 2" >
                                <div class="col-md-12">
                                    {{ 'enter_otp_code' | translate }}
                                </div>

                                <div class="col-md-12 mt-2 mb-2">
                                    <label>
                                        {{ 'verification_code' | translate }}
                                    </label>
                                    <input class="form-control" [(ngModel)]="code" />
                                </div>

                                <div class="col-md-12 mt-2 mb-2">
                                    <label>
                                        {{ 'your_new_password' | translate }}
                                    </label>
                                    <input class="form-control" [(ngModel)]="newPassword" />
                                </div>

                                <div class="col-md-12 mt-2 mb-2">
                                    <label>
                                        {{ 'your_new_password' | translate }} ({{ 'again' | translate }})
                                    </label>
                                    <input class="form-control" [(ngModel)]="newPasswordAgain" />
                                </div>

                                <ng-template [ngIf]="(hasError) && ((! checkNewPasswordComplex()) || (! checkNewPasswordAgain()))" >
                                    <div class="col-md-12 mt-2 mb-2">
                                        <ng-template [ngIf]="! checkNewPasswordAgain()" >
                                            {{ 'enter_same_password' | translate }} <br/>
                                        </ng-template>
                                        <ng-template [ngIf]="! checkNewPasswordComplex()" >
                                            {{ 'enter_complex_password' | translate }}
                                        </ng-template>
                                    </div>
                                </ng-template>


                                <div class="col-md-12 mt-2">
                                    <button class="btn btn-primary btn-block" (click)="verify()">
                                        {{ 'verify' | translate }}
                                    </button>
                                </div>
                            </ng-template>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
