import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Response } from './models/api/response.model';
import { User } from './models/user/user.model';
import { ApiService } from './services/admin/api/api.service';
import { AuthenticationService } from './services/admin/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuardGuard implements CanActivate {
  
  constructor( 
    private router: Router,
    private api: ApiService,
    private authentication: AuthenticationService
   ){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.authentication.checkIsLogin()
      .pipe(map((data: Response) => {
        if (!this.api.hasError(data)) {
          this.authentication.setRoles(data.data.roles);
          this.authentication.setLogineduser(data.data.user);
          return true;
        }
        this.router.navigate(['sign-in']);
        return false;
    }));
  }
  
}
