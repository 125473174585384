export class Language {
  constructor(
    public id: number,
    public name: string,
    public code: string,
    public isDefault: boolean
  ){}

  public static setGlobalList(list: Language[]): void{

    sessionStorage.setItem('languageList', JSON.stringify(list));

  }

  public static getGlobalList(): Language[]{

    return JSON.parse(sessionStorage.getItem('languageList'));

  }
  public static getDefaultGlobal(): Language{

    let i = Language.getGlobalList();
    return Language.activeLanguage(i);
  }
  public static activeLanguage(list: Language[]): Language{

    return list.find( (z: Language) => {
      if(z.isDefault) return z
    });

  }
}
