import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(
    private api: ApiService
  ) { }

  public list(): Observable<any>{
    return this.api.apiRequestAuth(`Language/language`);
  }
}
