import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {User} from '../../../models/user/user.model';
import {UiModalComponent} from '../../../theme/shared/components/modal/ui-modal/ui-modal.component';
import {GeneralService} from '../../../services/admin/general/general.service';

@Component({
  selector: 'app-user-preview-modal',
  templateUrl: './user-preview-modal.component.html',
  styleUrls: ['./user-preview-modal.component.scss'],
  providers: [UiModalComponent]
})
export class UserPreviewModalComponent implements OnInit {

  @Input() public user?: User;

  @ViewChild('modalDefault') private modal: UiModalComponent;
  constructor(
      private generalService: GeneralService
  ) { }

  ngOnInit(): void {

  }

  show(): void{

    this.modal.show();

  }

  public userProfile(): string{

    return this.generalService.getUserProfilePhoto(this.user.image);

  }

  public genderText(text?: string): string{
    return this.generalService.getGenderTranslation(text);
  }
}
