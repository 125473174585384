import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Pagination} from 'src/app/models/pagination/pagination.model';
import {User} from 'src/app/models/user/user.model';
import {ApiService} from '../api/api.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    public static COACH_LEVEL: number = 2;
    public static STUDENT_LEVEL: number = 1;
    public static ROLE_ADMIN = 1;
    public static ROLE_GYM_ADMIN = 2;

    constructor(
        private api: ApiService
    ) {
    }

    public static userLevelTypeList(translate: TranslateService, withAll: boolean = false, allDefiner?: number): { id?: number, name: string }[] {

        const list: { id?: number, name: string }[] = [];

        if (withAll) {
            list.push({
                id: allDefiner,
                name: translate.instant('all')
            });
        }

        list.push({
            id: UserService.COACH_LEVEL,
            name: translate.instant('coach')
        });
        list.push({
            id: UserService.STUDENT_LEVEL,
            name: translate.instant('student')
        });

        return list;
    }

    getStudentLevelList(withZero: number = 0): Observable<any> {
        return this.api.apiRequestAuth(`User/levelTypes`, {
            withZero: withZero
        });
    }

    getAdminUserInfo(): Observable<any> {
        return this.api.apiRequestAuth(`User/user`);
    }

    updateAdminUserInfo(name: string, surname: string, phone: string): Observable<any> {
        return this.api.apiRequestAuth(`User/Admin/updateAdminUser`, {
            name: name,
            phone: phone,
            surname: surname
        });
    }

    updateAdminProfilePhoto(photo: string): Observable<any> {
        return this.api.apiRequestAuth(`User/uploadProfilePhoto`, {
            image: photo
        });
    }

    changePassword(password: string, new_password: string): Observable<any> {
        return this.api.apiRequestAuth(`User/updatePassword`, {
            password: password, new_password: new_password
        });
    }

    listUsers(search: string, gender: string, role: string, order: string, page: number, studentLevel: number = -1, showOnlyGuest: boolean = false, hasPremium: string | boolean = 'all', withGuest: boolean = false): Observable<any> {
        return this.api.apiRequestAuth(`User/Admin/users`, {
            action: 'list',
            page,
            search,
            gender,
            role,
            order,
            studentLevel,
            showOnlyGuest,
            hasPremium,
            withGuest
        });
    }

    allUser(): Observable<any> {
        return this.api.apiRequestGet('User/Admin/users');
    }

    get(id: number): Observable<any> {
        return this.api.apiRequestAuth(`User/Admin/users`, {
            action: 'get',
            id: id
        });
    }

    managerList(search: string, page: number, pagePer: number, roleId: number, gymId?: number): Observable<any> {
        return this.api.apiRequestGet(`User/Admin/manager`, {
            roleId,
            page,
            pagePer,
            search,
            gymId
        });
    }

    searchAdminUserWithPhone(phone: string, roleId: number): Observable<any> {

        return this.api.apiRequestAuth(`User/Admin/users`, {
            action: 'getWithPhoneNoAdminUser',
            phone,
            roleId
        });

    }

    setUserManager(userId: number, roleId: number, gymId?: number): Observable<any> {
        return this.api.apiRequestAuth(`User/Admin/manager`, {
            userId,
            roleId,
            gymId
        });
    }

    createUser(name: string, surname: string, phone: string, password: string): Observable<any> {

        return this.api.apiRequestAuth(`User/Admin/users`, {
            action: 'create',
            name: name,
            surname: surname,
            phone: phone,
            password: password
        });

    }

    removeAdminAccess(userId: number, roleId: number): Observable<any> {
        return this.api.apiRequestDelete(`User/Admin/manager`, {
            userId,
            roleId
        });
    }

    updateUserProfilePhoto(userId: number, image: string): Observable<any> {

        return this.api.apiRequestAuth(`User/uploadProfilePhoto`, {
            userId: userId,
            image: image
        });

    }

    removeUserProfilePhoto(userId: number): Observable<any> {

        return this.api.apiRequestAuth(`User/deleteProfilePhoto`, {
            userId: userId
        });

    }

    setUserInfo(user: User): Observable<any> {
        if (user.about == null) {
            user.about = '';
        }
        return this.api.apiRequestAuth(`User/Admin/users`, {
            action: 'update',
            id: user.id,
            name: user.name,
            surname: user.surname,
            about: user.about,
            gender: user.gender,
            date_birth: user.date_birth
        });

    }

    setUserPassword(userId: number, password: string): Observable<any> {

        return this.api.apiRequestAuth(`User/Admin/users`, {
            action: 'setPassword',
            userId: userId,
            password: password
        });

    }

    deleteUsers(users: User[]): Observable<any> {
        return this.api.apiRequestDelete('User/Admin/users', {
            users
        });
    }

    setUserLevel(userId: number, level: number): Observable<any> {

        return this.api.apiRequestAuth(`User/setStudentLevel`, {
            userId: userId,
            levelType: level
        });

    }

    getUserPoints(userId: number, pagination: Pagination, startTime?: string, endTime?: string): Observable<any> {

        return this.api.apiRequestAuth(`User/Admin/userPointsCRUD`, {
            action: 'list',
            userId: userId,
            startTime: startTime,
            endTime: endTime,
            page: pagination.page,
            pagePer: pagination.pagePer
        });

    }

    addUserPoint(userId: number, value: number): Observable<any> {

        return this.api.apiRequestAuth(`User/Admin/userPointsCRUD`, {
            action: 'add',
            userId: userId,
            value: value
        });

    }

    removeUserCoachAccess(userId: number): Observable<any> {

        return this.api.apiRequestAuth(`User/Admin/users`, {
            action: 'removeUserCoachAccess',
            userId: userId
        });

    }

    listWithOptionalParameters(gender: string | null, minAge: number | null, maxAge: number | null, role: number | null, minDate: string | null, maxDate: string | null, pagination: Pagination): Observable<any> {

        return this.api.apiRequestAuth(`User/Admin/users`, {
            action: 'listMinMaxOptions',
            gender: gender,
            role: role,
            minAge: minAge,
            maxAge: maxAge,
            minLastActivity: minDate,
            maxLastActivity: maxDate,
            page: pagination.page,
            pagePer: pagination.pagePer
        });

    }

    listLastActiveUsers(minute: number, pagination: Pagination): Observable<any> {

        return this.api.apiRequestAuth(`User/Admin/users`, {
            action: 'list',
            page: pagination.page,
            pagePer: pagination.pagePer,
            lastActivityMinute: minute,
            withGuest: true
        });

    }

    listNewUsers(day: number, pagination: Pagination, withGuest: boolean = true, hasPremium: boolean | null = null): Observable<any> {
        return this.api.apiRequestAuth(`User/Admin/users`, {
            action: 'list',
            page: pagination.page,
            pagePer: pagination.pagePer,
            lastRegistrationDay: day,
            withGuest,
            hasPremium
        });
    }

    userRegistrationCounts(dayCount: number): Observable<any> {

        return this.api.apiRequestAuth(`User/Admin/users`, {
            action: 'registrationStatistics',
            dayCount
        });

    }

    getDevices(userId: number, pagination: Pagination): Observable<any> {

        return this.api.apiRequestAuth(`User/Admin/users`, {
            action: 'getDevices',
            userId,
            page: pagination.page,
            pagePer: pagination.pagePer
        });

    }

    listManagerDevices(pagination: Pagination): Observable<any> {

        return this.api.apiRequestGet(`User/Admin/manager/devices`, {
            page: pagination.page,
            pagePer: pagination.pagePer
        });


    }

    deleteManagerDevice(id: number): Observable<any> {

        return this.api.apiRequestDelete(`User/Admin/manager/devices`, {
            id
        });

    }
}
