<div class="col-md-12">
    <small class="text-muted">
        {{ 'total' | translate }} {{ totalCommentCount }} {{ 'comment' | translate }}
    </small>
</div>
<div class="col-md-12">
    <div class="row">
        <ng-container *ngFor="let cg of pointGroups;">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-6">
                        <span *ngFor="let star of getNumbers(cg.point);">
                            <i class="fa fa-star" style="color: #ffc107;"></i>
                        </span>
                    </div>
                    <div class="col-6">
                        {{ cg.commentCount }} {{ 'comment' | translate }}
                    </div>
                </div>

            </div>
        </ng-container>
    </div>
</div>
