import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AlertModule, BreadcrumbModule, CardModule, LoaderModule, ModalModule} from './components';
import {DataFilterPipe} from './components/data-table/data-filter.pipe';
import {TodoListRemoveDirective} from './components/todo/todo-list-remove.directive';
import {TodoCardCompleteDirective} from './components/todo/todo-card-complete.directive';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ClickOutsideModule} from 'ng-click-outside';

import {SpinnerComponent} from './components/spinner/spinner.component';
import {ApexChartComponent} from './components/chart/apex-chart/apex-chart.component';
import {ApexChartService} from './components/chart/apex-chart/apex-chart.service';
import {ToastComponent} from './components/toast/toast.component';
import {ToastService} from './components/toast/toast.service';
import {GalleryComponent} from './components/gallery/gallery.component';
import {LightboxModule} from 'ngx-lightbox';
import {NotificationComponent} from './components/notification/notification.component';
import {ApiService} from 'src/app/services/admin/api/api.service';
import {Language} from 'src/app/models/localization/language.model';
import {LanguageService} from 'src/app/services/admin/language/language.service';
import {LoaderComponent} from './components/loader/loader.component';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {SelectUserSearchComponent} from '../../admin/share/select-user-search/select-user-search.component';
import {UserPreviewModalComponent} from '../../admin/share/user-preview-modal/user-preview-modal.component';
import {AgePipe} from '../../pipe/age/age.pipe';
import {CommentsGeneralPrewiewComponent} from '../../admin/share/comments-general-prewiew/comments-general-prewiew.component';
import {PhoneInputComponent} from '../../admin/share/phone-input/phone-input.component';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {NgbDatepickerModule, NgbDropdownModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {DatePickerComponent} from '../../admin/share/date-picker/date-picker.component';
import {PaginationComponent} from '../../admin/share/pagination/pagination.component';
import {TimeInputComponent} from '../../admin/share/time-input/time-input.component';
import {TextMaskModule} from 'angular2-text-mask';
import {UserBlockComponent} from '../../admin/share/user-block/user-block.component';


/*import 'hammerjs';
import 'mousetrap';
import { GalleryModule } from '@ks89/angular-modal-gallery';*/

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    imports: [
        CommonModule,
        PerfectScrollbarModule,
        FormsModule,
        ReactiveFormsModule,
        AlertModule,
        CardModule,
        BreadcrumbModule,
        ModalModule,
        ClickOutsideModule,
        LightboxModule,
        LoaderModule,
        TranslateModule,
        NgbDropdownModule,
        NgxIntlTelInputModule,
        NgxIntlTelInputModule,
        NgbDatepickerModule,
        NgbPaginationModule,
        TextMaskModule
    ],
    exports: [
        CommonModule,
        PerfectScrollbarModule,
        FormsModule,
        ReactiveFormsModule,
        AlertModule,
        CardModule,
        BreadcrumbModule,
        ModalModule,
        DataFilterPipe,
        TodoListRemoveDirective,
        TodoCardCompleteDirective,
        ClickOutsideModule,
        SpinnerComponent,
        ApexChartComponent,
        GalleryComponent,
        ToastComponent,
        LoaderModule,
        TranslateModule,
        SelectUserSearchComponent,
        UserPreviewModalComponent,
        CommentsGeneralPrewiewComponent,
        PhoneInputComponent,
        DatePickerComponent,
        PaginationComponent,
        TimeInputComponent,
        UserBlockComponent

    ],
    declarations: [
        DataFilterPipe,
        TodoListRemoveDirective,
        TodoCardCompleteDirective,
        SpinnerComponent,
        ApexChartComponent,
        ToastComponent,
        GalleryComponent,
        NotificationComponent,
        SelectUserSearchComponent,
        AgePipe,
        UserPreviewModalComponent,
        CommentsGeneralPrewiewComponent,
        PhoneInputComponent,
        DatePickerComponent,
        PaginationComponent,
        TimeInputComponent,
        UserBlockComponent

    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        ApexChartService,
        ToastService,
        NotificationComponent,
        LoaderComponent,
        SelectUserSearchComponent,
        UserPreviewModalComponent,
        CommentsGeneralPrewiewComponent,
        PhoneInputComponent,
        DatePickerComponent,
        PaginationComponent,
        TimeInputComponent,
        UserBlockComponent
    ]
})
export class SharedModule {

    public languages: Language[] = [];

    constructor(
        private languageService: LanguageService,
        private api: ApiService,
        private translateService: TranslateService
    ) {

        this.translateService.addLangs(['tr', 'en']);
        this.translateService.setDefaultLang('tr');
        this.translateService.use('tr');

        this.languageService.list()
            .subscribe(
                data => {
                    if (!(this.api.hasError(data))) {
                        this.languages = data.data.list;
                        Language.setGlobalList(data.data.list);
                    }
                }
            );
    }
}
