import {Component, Input, OnInit} from '@angular/core';
import {PointGroups} from '../../../models/comment/point-groups.model';

@Component({
    selector: 'app-comments-general-prewiew',
    templateUrl: './comments-general-prewiew.component.html',
    styleUrls: ['./comments-general-prewiew.component.scss']
})
export class CommentsGeneralPrewiewComponent implements OnInit {

    @Input() public totalCommentCount: number;
    @Input() public pointGroups: PointGroups[];

    constructor() {
    }

    ngOnInit(): void {
    }


    public getNumbers(length: number): any[]{

        return (new Array(length));

    }
}
